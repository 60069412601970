import {TreeValuesResponse} from "../ApiTypes";
import {L1To8} from "../ApiHelpers";

export type Decimal = number // Although we receive a string from the API

// Backend classes

export enum ReviewChoice {
    ACCEPT,
    REJECT,
}

export type Categories = string[]

declare type AggregatedState = 'approved'
    | 'not_approved_review_open'
    | 'not_approved_review_accept'
    | 'waiting_approval'
    | 'open'
    | 'review_accepted'
    | 'mix'

/**
 * The part of the MatSGroup that is aggregated
 */
export declare type MatSGroupAggregate = {
    sum_spend: Decimal
    n_parts: number

    /**
     * The active category is uncategorized everywhere
     */
    all_category_is_uncat: boolean
    /**
     * The same active category is used everywhere
     */
    category: string[] | false
    /**
     * There is a part that is reviewed by some else then the current user
     */
    reviewed_by_other: boolean

    /**
     * There is a suggestion open for review that cannot be accepted
     */
    exists_invalid_suggestion: boolean
    /**
     * All suggestions that are open for review cannot be accepted
     */
    all_invalid_suggestion: boolean
    /**
     * There is a suggestion that is open for review
     */
    exists_open_suggestion: boolean
    /**
     * Everything has been reviewed
     */
    all_is_reviewed: boolean

    exists_approval_set: boolean
    exists_approval_applied: boolean
    all_approval_set: boolean
    all_approval_applied: boolean
    all_feedback_choice_accept: boolean
    all_feedback_choice_reject: boolean
    "supplier_id": number,
    "mat_s_group_ids": number[],
}
/**
 * A response from API that is always grouped by supplier
 */
export declare type MatSGroup = MatSGroupAggregate & {
    /**
     * All the accompanied atomic database entries of this group
     */
    mat_s_group_ids: number[]
    supplier_id: number // Because we group by supplier, this field is always present
    s_name: string
    p_l1_agg?: string[]
    bu_name_agg?: string[]
}

export type MatPartReviewRow = {
    id: number

    p_id: string
    p_name: string
    p_description: string

    p_spend: Decimal
    p_context_1: string
    p_context_2: string
    p_context_3: string

    p_date_1: string
    p_date_2: string
    p_date_3: string
    p_ddate_1: string
    p_ddate_2: string
    p_ddate_3: string

    s_name: string
    sp_name: string
    bu_name: string
    p_group_name: string

    p_prev_cat_l1: string
    p_prev_cat_l2: string
    p_prev_cat_l3: string
    p_prev_cat_l4: string
    p_prev_cat_l5: string
    p_prev_cat_l6: string
    p_prev_cat_l7: string
    p_prev_cat_l8: string

    p_suggestion_score: number
    p_suggested_cat_l1: string
    p_suggested_cat_l2: string
    p_suggested_cat_l3: string
    p_suggested_cat_l4: string
    p_suggested_cat_l5: string
    p_suggested_cat_l6: string
    p_suggested_cat_l7: string
    p_suggested_cat_l8: string

    review_choice: ReviewChoice | null
    review_user_id: number | null
    // review_notes: string

    p_review_cat_l1: string
    p_review_cat_l2: string
    p_review_cat_l3: string
    p_review_cat_l4: string
    p_review_cat_l5: string
    p_review_cat_l6: string
    p_review_cat_l7: string
    p_review_cat_l8: string

    approval: number | null
    approval_applied: boolean

    feedback_choice: ReviewChoice | null
    feedback_user_id: number | null
    // feedback_notes: string
    databag: number
}

export type MatPartReviewRowWithContext = MatPartReviewRow & {
    context: { [l in L1To8]: string }
}

export type MatReviewStatisticsSerializer = {
    databag_id: number
    total_spend: Decimal
    total_parts: number
    cat_new_spend: Decimal
    cat_new_parts: number
    cat_same_spend: Decimal
    cat_same_parts: number
    cat_recat_spend: Decimal
    cat_recat_parts: number
}
export type MatReviewLevelStatisticsTreeSerializer = TreeValuesResponse<{
    pre_spend: number
    post_spend: number
    pre_parts: number
    post_parts: number
    pre_suppliers: number
    post_suppliers: number
    pre_uncategorized_spend: number | undefined // Can be undefined in old backend versions
    post_uncategorized_spend: number | undefined // Can be undefined in old backend versions
    pre_uncategorized_parts: number | undefined // Can be undefined in old backend versions
    post_uncategorized_parts: number | undefined // Can be undefined in old backend versions
}>


export type MatConcentrationStatistics = {
    databag: number
    total_parts: number
    total_spend: Decimal
    total_suppliers: number
    top_n_percentage: number
    top_n_spend: Decimal
    top_n_spend_percentage: Decimal
}

export type MatCategoryConcentrationStatistics = {
    id: number
    level: number
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string
    databag: number

    total_spend: Decimal
    distinct_suppliers: number
    top_n_percentage: number
    top_n_spend: Decimal
    top_n_spend_percentage: number
}

export type MatSupplierCategoryConcentrationStatistics = {
    id: number
    level: number
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string
    databag: number

    supplier_id: number
    s_id: string
    s_name: string

    s_c_spend: Decimal
    s_c_cumulative_spend: number
    s_c_cumulative_spend_percentage: number
    s_total_spend: Decimal
    s_total_cumulative_spend: number
    s_total_cumulative_spend_percentage: number
}

export type PartData = {
    p_id: string
    p_name: string
    p_description: string
    p_spend: Decimal
    p_context_1: string
    p_context_2: string
    p_context_3: string
    p_date_1: string
    p_date_2: string
    p_date_3: string
    p_ddate_1: string
    p_ddate_2: string
    p_ddate_3: string
}

export type BusinessUnit = {
    id: number
    databag_id: number
    bu_id: string
    bu_name: string
}

export type ApplyTaxonomyMappingRequestData = {
    src_mat_taxonomy: number
    dst_mat_taxonomy: number
    map_src_classification_databag: number
    map_dst_classification_databag: number
}

export type MaterializeRequest = {
    databag: number
    clear: boolean
}


export type PpvGroup = {
    id: number
    databag: number
    group_key: string
    group_size: number
    unit_cost_min: number
    unit_cost_max: number
    unit_cost_avg: number
    quantity_sum: number
    spend_sum: number
    n_supplier_ids: number
    ppv_delta: number
    ppv: number
    ppv_opportunity: number
}
export type PpvStatistic = {
    id: number
    databag: number
    level: number
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string

    total_ppv_opportunity: number
    // groups: PpvGroup[]
}
