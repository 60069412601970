import {ApiService, mithra_http} from "./Http";
import {AxiosResponse} from "axios";
import {
    DataFrameSerializer,
    DataMappingResultTableSerializer,
    DataMappingSerializer,
    KpiListSerializer,
    MithraColumnSerializer
} from "./ApiTypes";
import {environment} from "../env";
import {IngestionDataset} from "./classes/IngestionClasses";

export default class MithraDataIngestionApi extends ApiService {

    getDatasetList(): Promise<IngestionDataset[]> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_ingestions.json').then(r => r.data);
        return this.http.get(`/data_ingestion/`).then(r => {
            return r.data;
        });
    }

    getDataset(id: number): Promise<IngestionDataset> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_ingestion.json').then(r => r.data);
        return this.http.get(`/data_ingestion/${id}`).then(r => {
            return r.data;
        });
    }

    uploadDatafile(file: File, name: string): Promise<IngestionDataset> {
        if (environment.isDemo) return mithra_http.mockApiSlow.get('/sales_demo/ingestion/data_ingestion.json').then(r => r.data);
        let formData = new FormData();
        formData.append("excel_file", file);
        formData.append("name", name);

        return this.http
            .post(`/data_upload/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((r) => {
                return r.data;
            });
    }

    getAllowedOperations(): Promise<any> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/allowed_operations.json').then(r => r.data);
        return this.http.get(`/allowed_operations/`).then((r) => {
            return r.data;
        });
    }

    getAllMithraColumns(): Promise<MithraColumnSerializer[]> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/mithra_columns.json').then(r => r.data);
        return this.http.get(`/mithra_columns/`).then((r) => {
            return r.data;
        });
    }

    getAllClientColumns(dataFileId: number): Promise<DataFrameSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/dataframe_getter.json').then(r => r.data);
        return this.http.get(`/data_upload/${dataFileId}/dataframe_getter/`).then((r) => {
            return r.data;
        });
    }

    getAllDataMappingList(): Promise<any> {
        return this.http.get(`/data_mapping/`).then((r) => {
            return r.data;
        });
    }

    getDataMappingList(dataMappingId: number): Promise<DataMappingSerializer[]> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_mapping.json').then(r => r.data);
        return this.http.get(`/data_mapping/?data_file=${dataMappingId}&client_column_index=&mithra_column_key=`).then((r) => {
            return r.data;
        });
    }

    postDataMappingList(dataMappingList: DataMappingSerializer[]): Promise<DataMappingSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/data_mapping.json').then(r => r.data);
        return this.http.post(`/data_mapping/post_mappings_list/`, dataMappingList).then((r) => {
            return r.data;
        });
    }

    getDataMappingResult(fileId: number): Promise<DataMappingSerializer[]> { //client_column_index?: number, mithra_column_key?: string
        return this.http.get(`/data_mapping/?data_file=${fileId}&client_column_index=&mithra_column_key=`).then((r) => {//${client_column_index}&mithra_column_key=${mithra_column_key}
            return r.data;
        });
    }

    startAiDataMapping(dataFileId: number) {
        if (environment.isDemo) return Promise.resolve();
        return this.http.post(`/data_upload/${dataFileId}/start_ai_column_mapping/`);
    }

    applyDataMapping(dataFileId: number): Promise<AxiosResponse<void>> {
        if (environment.isDemo) return mithra_http.mockApiSlow.get('/sales_demo/ingestion/get_mapping_result.json').then(r => r.data);
        return this.http
            .post(`/data_ingestion/${dataFileId}/apply_mapping/`)
            .then((r) => {
                return r.data;
            });
    }

    getMappingResultTable(dataFileId: number): Promise<DataMappingResultTableSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/get_mapping_result.json').then(r => r.data);
        return this.http.get(`/data_ingestion/${dataFileId}/get_mapping_result/`).then((r) => {
            return r.data;
        });
    }

    getKpiList(dataFileId: number): Promise<KpiListSerializer> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/ingestion/kpi_bundle.json').then(r => r.data);
        return this.http.get(`/bag/kpi_common/${dataFileId}/kpi_bundle/`).then((r) => {
            return r.data;
        });
    }
}
