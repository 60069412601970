import "./DataMapping.scss"
import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid} from "@mui/material";
import {generateGotoDashboardPath} from "../../../routing/routing.utils";
import {useRouteMatch} from "react-router-dom";
import {useHistory} from "react-router";
import {useStores} from "../../../stores";
import {Bag} from "../../../services/classes/Bag";
import {JobRouteMatch} from "../../../routing/routes";
import {C} from "../../../configurations/active-configuration";


export const DatasetFinish: React.FC = observer(() => {
    const {rootAppStore, bagStore, dataIngestionStore} = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();

    const bag = bagStore.bag as Bag | undefined;

    return <div className="data-finish" style={{marginTop: "12%"}}>
        <Grid>
            <Grid xs={12} container justifyContent="center" alignItems="center">
                <h2>Finished creating a new dataset!</h2>
            </Grid>
            <Grid xs={12} container justifyContent="center" alignItems="center">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        dataIngestionStore.resetIngestionStore();
                        dataIngestionStore.resetErrorToShow();

                        let databagId = bag?.id;
                        if (C.profile.demoFakeDataIngestionTarget) {
                            databagId = C.profile.demoFakeDataIngestionTarget.databagId;
                        }
                        history.push(generateGotoDashboardPath(routeMatch, {id: String(databagId)}, rootAppStore.app));
                    }}>
                    To the dashboard
                </Button>
            </Grid>
        </Grid>
    </div>
})