/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";
export const C: AppConfiguration = {
    package: 'cleanup',
    dashboardVariant: 'simple',
    menu: 'single',
    profile: {
        p_name_col_name: 'Document',
        p_description_col_name: 'Description',

        s_col_name: 'Vendor',
        bu_col_name: 'Company Name',

        p_context_1_col_name: 'Description 2',
        p_context_1_col_class: 'col-smaller-text',
        p_context_3_col_name: 'Material Group',
        p_context_pref: [1, 3],

        show_bu_name_in_review_pages: true,
        p_ddate_pref: [1],
        p_ddate_1_col_name: 'Date',
        p_ddate_1_col_type: 'date',

        p_name_col_width: 200,
        p_description_col_width: 200,
        p_ddate_1_col_width: 90,
        s_name_col_width: '14em',
        p_previous_l_col_width: 325,
        p_suggested_l_col_width: 325,
        p_bu_name_col_width: '5em',

        showSupplierIdInSupplierNormalization: true,
        showSupplierIdPrefixCutoffHack: 4,

        showBuWithId: true,
        datasetHasMissingBu: true,
        showBuIdPrefixCutoffHack: 4,
        searchConfiguration: 'default_p_desc',

        catFilterSearchAlsoPC3: true, // TODO: CAT-1649

        disablePreloadAdvDashboard: true,

        locale: 'nl-nl',
        currency: 'EUR',
        categorizationShowScore: true,

        skipAiSupplierNormalizationModel: true,
        skipAiTaxonomyMapperModel: true,
        hackCategorizationUseLatestCategories: true,

        categorizationReviewSubRowRelationData: [{
            relation: "part",
            columns: [
                {db_column: "p_gl_code", colSpec: {cls: 'col-part_gl_code', txt: 'GL Code', width: '6.5em'}},
                {db_column: "p_gl_name", colSpec: {cls: 'col-part_gl_name', txt: 'GL Name', width: '9em'}},
            ]
        }],
        s_context_2_col_name: 'Short name',

        taxonomy_builder_only_n_cats: true,

        disableReviewStatisticsPage: true,
        hardcodedOriginalTaxonomyId: 7,
        showSupplierIdInAdvDashboard: true,
    },
}
