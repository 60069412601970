import './DataIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Grid} from "@mui/material";
import {DatasetMapping} from './data-mapping/DatasetMapping';
import {DatasetCheck} from './data-mapping/DatasetCheck';
import {DatasetFinish} from './data-mapping/DatasetFinish';
import {DataIngestionStepper} from "./DataIngestionStepper";


type Props = {
    viewer?: boolean
}
export const DataMappingPage: React.FC<Props> = observer(() => {
    const {dataIngestionStore, p} = useStores();

    useEffect(() => {
        if (!dataIngestionStore.page || dataIngestionStore.page === 'dataset_upload') {
            dataIngestionStore.navigateToPage('data_mapping')
        }
    }, [dataIngestionStore, p])

    return <div className="data-ingestion data-mapping-page">

        {dataIngestionStore.errorsToShow.length !== 0 ? dataIngestionStore.errorsToShow.map((msg, i) => {
            return <Alert key={i} severity="error">{JSON.stringify(msg)}</Alert>
        }) : null}

        <DataIngestionStepper defaultPage={'data_mapping'}></DataIngestionStepper>

        <Grid container justifyContent="center" className="data-ingestion-page-content">
            <Grid item xs={10}> {dataIngestionStore.page === 'data_mapping' && <DatasetMapping/>} </Grid>
            <Grid item xs={11}> {dataIngestionStore.page === 'data_check' && <DatasetCheck/>} </Grid>
            <Grid item xs={10}> {dataIngestionStore.page === 'data_finish' && <DatasetFinish/>} </Grid>
        </Grid>
    </div>
});
