import React, {useEffect} from "react";
import {useLocation} from "react-router";
import {environment} from "../../env";
import {useRouteMatch} from "react-router-dom";
import {getAnalytics, logEvent} from "firebase/analytics";

export const AnalyticsCollector: React.FC = ({children}) => {
    const location = useLocation();
    const routeMatch = useRouteMatch();
    useEffect(() => {
        if (location) {
            const url = location.pathname + location.search;
            const title = routeMatch.url;
            console.log(`AnalyticsCollector ${environment.isGA ? ' (GA)' : ''}`, title, url);
            if (environment.isGA) {
                const analytics = getAnalytics();
                logEvent(analytics, 'page_view', {page_title: title, page_location: url});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return <>{children}</>;
}