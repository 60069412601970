import React, {useState} from "react";
import {initializeApp} from "firebase/app";
import {FirebaseApp} from "@firebase/app";
import {environment} from "../../env";
import {Analytics, initializeAnalytics, logEvent} from "firebase/analytics";
import git from "../../utils/git.json";

export type FirebaseContextType = {
    app: FirebaseApp;
    ga?: Analytics;
}

export const FirebaseContext = React.createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC = ({children}) => {
    const [firebase] = useState<FirebaseContextType | undefined>(
        () => {
            if (!environment.firebaseConfig.apiKey) {
                console.log('No firebase setup; not initializing app')
                return undefined;
            }
            // Initialize Firebase
            const app = initializeApp(environment.firebaseConfig);
            let ga: Analytics | undefined = undefined;
            const firebaseAppName = app.name;
            if (environment.isGA) {
                ga = initializeAnalytics(app, {
                    config: {
                        // We send our custom page views
                        send_page_view: false,
                    }
                });
                const hostname = window.location.hostname;
                logEvent(ga, 'app_init', {
                    version: git.gitCommitHash,
                    customer: environment.customerName,
                    hostname,
                })
                console.log(`Firebase analytics initialized with app name: ${firebaseAppName}`)
            } else {
                console.log(`Firebase initialized with app name: ${firebaseAppName}`)
            }
            return {app, ga};
        }
    );
    return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
}