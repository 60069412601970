import React, {FC, useEffect, useState} from 'react'
import {ShadcnButton} from './ShadcnButton'
import {PopoverContent, PopoverTrigger, ShadcnPopover} from './ShadcnPopover'
import {ShadcnCalendar} from './ShadcnCalendar'
import {ShadcnDateInput} from './ShadcnDateInput'
import {SelectContent, SelectItem, SelectTrigger, SelectValue, ShadcnSelect} from './ShadcnSelect'
import {CheckIcon, ChevronDownIcon, ChevronUpIcon} from '@radix-ui/react-icons'
import {cn} from '../../lib/utils'
import moment from "moment";


declare type DateRangePickerProps = {
    /** Click handler for applying the updates from DateRangePicker. */
    onUpdate: (values: { range: DateRange }) => void
    /** Current value for start date */
    dateFrom?: string | undefined
    /** Current value for end date */
    dateTo?: string | undefined
    /** Alignment of popover */
    align?: 'start' | 'center' | 'end'
}

interface DateRange {
    from: Date
    to: Date | undefined
}

interface Preset {
    name: string
    label: string
}

const CURRENT_YEAR = new Date().getFullYear();

// Define presets
const PRESETS: Preset[] = [
    // {name: 'last30', label: 'Last 30 days'},
    {name: 'currentQuarter', label: 'Current Quarter'},
    {name: 'lastQuarter', label: 'Last Quarter'},
    {name: 'thisMonth', label: 'This Month'},
    {name: 'lastMonth', label: 'Last Month'},
    {name: 'yearToDate', label: 'Year to Date'},
    {name: 'lastYear', label: 'Last Year'},
    {name: 'Q1', label: `Q1 ${CURRENT_YEAR - 1}`},
    {name: 'Q2', label: `Q2 ${CURRENT_YEAR - 1}`},
    {name: 'Q3', label: `Q3 ${CURRENT_YEAR - 1}`},
    {name: 'Q4', label: `Q4 ${CURRENT_YEAR - 1}`},
]

/** The DateRangePicker component allows a user to select a range of dates */
export const ShadcnDateRangePicker: FC<DateRangePickerProps> = ({
                                                                    dateFrom,
                                                                    dateTo,
                                                                    onUpdate,
                                                                    align = 'end'
                                                                }) => {
    const [isOpen, setIsOpen] = useState(false)

    const [range, setRange] = useState<DateRange>({
        from: dateFrom ? new Date(dateFrom) : new Date(),
        to: dateTo ? new Date(dateTo) : (dateFrom ? new Date(dateFrom) : new Date())
    })
    useEffect(() => {
        setRange({
            from: dateFrom ? new Date(dateFrom) : new Date(),
            to: dateTo ? new Date(dateTo) : (dateFrom ? new Date(dateFrom) : new Date())
        })
    }, [dateFrom, dateTo]);

    const [selectedPreset, setSelectedPreset] = useState<string | undefined>(undefined)

    const [isSmallScreen, setIsSmallScreen] = useState(
        typeof window !== 'undefined' ? window.innerWidth < 960 : false
    )

    useEffect(() => {
        const handleResize = (): void => {
            setIsSmallScreen(window.innerWidth < 960)
        }

        window.addEventListener('resize', handleResize)

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const getPresetRange = (presetName: string): DateRange => {
        const preset = PRESETS.find(({name}) => name === presetName)
        if (!preset) throw new Error(`Unknown date range preset: ${presetName}`)
        const from = new Date()
        const to = new Date()
        from.setHours(0, 0, 0, 0)
        to.setHours(0, 0, 0, 0)


        const currentMonth = from.getMonth();
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3; // Find the start month of the current quarter

        const lastQuarterStartMonth = quarterStartMonth - 3 < 0 ? 9 : quarterStartMonth - 3; // Find the start month of the last quarter
        const lastQuarterEndMonth = quarterStartMonth - 1; // End month of last quarter

        switch (preset.name) {
            // case 'last30':
            //     from.setDate(from.getDate() - 29)
            //     from.setHours(0, 0, 0, 0)
            //     to.setHours(23, 59, 59, 999)
            //     break
            case 'Q1':
            case 'Q2':
            case 'Q3':
            case 'Q4':
                const quarterNumber = parseInt(preset.name.slice(1)); // Extracting the numeric part of the quarter name
                from.setFullYear(CURRENT_YEAR - 1)
                from.setMonth(quarterNumber * 3 - 3)
                from.setDate(1)
                to.setFullYear(CURRENT_YEAR - 1)
                to.setMonth(quarterNumber * 3)
                to.setDate(0)
                break;
            case 'thisMonth':
                from.setDate(1)
                to.setMonth(from.getMonth() + 1)
                to.setDate(0)
                break
            case 'lastMonth':
                from.setMonth(from.getMonth() - 1)
                from.setDate(1)
                to.setMonth(from.getMonth() + 1)
                to.setDate(0)
                break
            case 'currentQuarter':
                const quarterStartMonth1 = Math.floor(currentMonth / 3) * 3; // Find the start month of the current quarter
                const nextQuarterStartMonth = quarterStartMonth + 3 > 11 ? 0 : quarterStartMonth + 3; // Find the start month of the next quarter
                const nextQuarterStartYear = quarterStartMonth + 3 > 11 ? from.getFullYear() + 1 : from.getFullYear(); // Find the year of the next quarter

                from.setMonth(quarterStartMonth1);
                from.setDate(1);
                to.setFullYear(nextQuarterStartYear, nextQuarterStartMonth, 0);
                break;
            case 'lastQuarter':
                const startYear = lastQuarterStartMonth <= currentMonth ? from.getFullYear() : from.getFullYear() - 1;
                const endYear = lastQuarterEndMonth <= currentMonth ? from.getFullYear() : from.getFullYear() - 1;

                from.setMonth(lastQuarterStartMonth);
                from.setFullYear(startYear);
                from.setDate(1);

                to.setMonth(lastQuarterEndMonth + 1);
                to.setFullYear(endYear);
                to.setDate(0); // Set to the last day of the previous month
                break;
            case 'yearToDate':
                from.setDate(1)
                from.setMonth(0)
                break
            case 'lastYear':
                from.setFullYear(from.getFullYear() - 1)
                from.setMonth(0)
                from.setDate(1)
                to.setFullYear(to.getFullYear() - 1)
                to.setMonth(11)
                to.setDate(31)
                break
        }

        return {from, to}
    }
    const setPreset = (preset: string): void => {
        const range = getPresetRange(preset)
        setRange(range)
    }

    const checkPreset = (): void => {
        for (const preset of PRESETS) {
            const presetRange = getPresetRange(preset.name)

            const normalizedRangeFrom = new Date(dateFrom ?? 0)
            const normalizedPresetFrom = new Date(
                presetRange.from.setHours(0, 0, 0, 0)
            )

            const normalizedRangeTo = new Date(dateTo ?? 0)
            const normalizedPresetTo = new Date(
                presetRange.to?.setHours(0, 0, 0, 0) ?? 0
            )

            if (
                normalizedRangeFrom.getTime() === normalizedPresetFrom.getTime() &&
                normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
            ) {
                setSelectedPreset(preset.name)
                return
            }
        }

        setSelectedPreset(undefined)
    }

    useEffect(() => {
        checkPreset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [range])

    const PresetButton = ({
                              preset,
                              label,
                              isSelected
                          }: {
        preset: string
        label: string
        isSelected: boolean
    }): JSX.Element => (
        <ShadcnButton
            className={cn(isSelected && 'pointer-events-none')}
            variant="ghost"
            onClick={() => {
                setPreset(preset);
            }}
        >
            <>
        <span className={cn('tw-pr-2 tw-opacity-0', isSelected && 'tw-opacity-70')}>
            <CheckIcon width={18} height={18}/>
        </span>
                {label}
            </>
        </ShadcnButton>
    )

    return (
        <ShadcnPopover modal={true} open={isOpen} onOpenChange={(open: boolean) => {
            setIsOpen(open);
        }}>
            <PopoverTrigger asChild>
                <button className="date-range-button">
                    <div className="tw-text-right">
                        <div className="tw-py-1">
                            <div>
                                {moment(range.from,).format('YYYY-MM-DD')}
                                {range.to ? ` - ${moment(range.to).format('YYYY-MM-DD')}` : ''}
                            </div>
                            {/*<div>{`${formatDate(range.from, p.p.locale)}${*/}
                            {/*    (range.to != null) ? ' - ' + formatDate(range.to, p.p.locale) : ''*/}
                            {/*}`}</div>*/}
                        </div>
                    </div>
                    <div className="tw-pl-1 tw-opacity-60 tw--mr-2 tw-scale-125">
                        {isOpen ? (<ChevronUpIcon width={24}/>) : (<ChevronDownIcon width={24}/>)}
                    </div>
                </button>
            </PopoverTrigger>
            <PopoverContent align={align} className="tw-w-auto">
                <div className="tw-flex tw-py-2">
                    <div className="tw-flex">
                        <div className="tw-flex tw-flex-col">
                            <div
                                className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 tw-px-3 tw-justify-end tw-items-center lg:tw-items-start tw-pb-4 lg:tw-pb-0">
                                <div className="tw-flex tw-flex-col tw-gap-2">
                                    <div className="tw-flex tw-gap-2">
                                        <ShadcnDateInput
                                            value={range.from}
                                            onChange={(date) => {
                                                const toDate = (range.to == null) || date > range.to ? date : range.to;
                                                setRange((prevRange) => ({
                                                    ...prevRange,
                                                    from: date,
                                                    to: toDate
                                                }));
                                            }}
                                        />
                                        <div className="tw-py-1">-</div>
                                        <ShadcnDateInput
                                            value={range.to}
                                            onChange={(date) => {
                                                const fromDate = date < range.from ? date : range.from;
                                                setRange((prevRange) => ({
                                                    ...prevRange,
                                                    from: fromDate,
                                                    to: date
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {isSmallScreen && (
                                <ShadcnSelect defaultValue={selectedPreset} onValueChange={(value) => {
                                    setPreset(value);
                                }}>
                                    <SelectTrigger className="tw-w-[180px] tw-mx-auto tw-mb-2">
                                        <SelectValue placeholder="ShadcnSelect..."/>
                                    </SelectTrigger>
                                    <SelectContent>
                                        {PRESETS.map((preset) => (
                                            <SelectItem key={preset.name}
                                                        value={preset.name}>{preset.label}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </ShadcnSelect>
                            )}
                            <div>
                                <ShadcnCalendar
                                    mode="range"
                                    onSelect={(value: { from?: Date, to?: Date } | undefined) => {
                                        if ((value?.from) != null) {
                                            setRange({from: value.from, to: value?.to});
                                        }
                                    }}
                                    selected={range}
                                    numberOfMonths={isSmallScreen ? 1 : 2}
                                    defaultMonth={
                                        new Date(new Date().setMonth(new Date().getMonth() - (isSmallScreen ? 0 : 1)))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {!isSmallScreen && (
                        <div className="tw-flex tw-flex-col tw-items-end tw-gap-1 tw-pr-2 tw-pl-6 tw-pb-6">
                            <div
                                className="tw-flex tw-flex-col tw-overflow-y-auto tw-max-h-80 tw-items-end tw-gap-1.5 tw-pr-2 tw-pl-6 tw-pb-6">
                                {PRESETS.map((preset) => (
                                    <PresetButton
                                        key={preset.name}
                                        preset={preset.name}
                                        label={preset.label}
                                        isSelected={selectedPreset === preset.name}
                                    />
                                ))}
                            </div>
                        </div>)}
                </div>
                <div className="tw-flex tw-justify-end tw-gap-2 tw-py-2 tw-pr-4">
                    <ShadcnButton
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        variant="ghost"
                    >
                        Cancel
                    </ShadcnButton>
                    <ShadcnButton
                        onClick={() => {
                            setIsOpen(false);
                            onUpdate?.({range});
                        }}
                    >
                        Update
                    </ShadcnButton>
                </div>
            </PopoverContent>
        </ShadcnPopover>
    )
}
