import './DataIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Button, Grid, TextField} from "@mui/material";
import {DataIngestionStepper} from "./DataIngestionStepper";
import {FileUploadSingle} from "./FileUploadSingle";
import {ChevronRight} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";

type Props = {
    viewer?: boolean
}
export const DataUploadPage: React.FC<Props> = observer(() => {
    const {dataIngestionStore, p} = useStores();
    const history = useHistory();

    useEffect(() => {
        dataIngestionStore.navigateToPage('dataset_upload')
    }, [dataIngestionStore, p])

    const readyForNext = dataIngestionStore.dataFileId !== undefined && !dataIngestionStore.datasetUploadIsLoading;

    return <div className="data-ingestion data-upload-page">
        <DataIngestionStepper defaultPage={"dataset_upload"}></DataIngestionStepper>

        <Grid container justifyContent="center" className="data-ingestion-page-content">
            <Grid item xs={10}>

                <div className="dataset-upload">

                    {dataIngestionStore.errorsToShow.length !== 0 ? dataIngestionStore.errorsToShow.map((msg, i) => {
                        return <Alert key={i} severity="error">{JSON.stringify(msg)}</Alert>
                    }) : null}

                    <Grid container justifyContent="center" style={{marginTop: '3em'}}>
                        <Grid xs={7} item>
                            <TextField fullWidth id="outlined-basic" label="Type the name of the new dataset here"
                                       variant="outlined" value={dataIngestionStore.datasetName}
                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                           dataIngestionStore.setDatasetName(event.target.value);
                                       }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center">
                        <Grid item style={{margin: '3em'}}>
                            <FileUploadSingle/>
                        </Grid>
                        <Grid item style={{margin: '3em'}}>
                            <Button
                                variant="contained"
                                disabled={!readyForNext}
                                // endIcon={<Check/>}
                                endIcon={<ChevronRight/>}
                                onClick={() => {
                                    history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataIngestionStore.dataFileId)}))
                                }}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </div>

            </Grid>
        </Grid>
    </div>
});
