import {User} from "./classes/User";
import {Country} from "../components/visualization/bubble-map/countries";

export const GROUP_SUPER = 'customer_super' as const;
export const GROUP_USER = 'customer_user' as const;
export const GROUP_READ_ONLY = 'customer_readonly' as const;

export declare interface GraphResponseNode {
    nodeId: number;
    name: string;
}

export declare interface GraphResponseLink<D> {
    source: number;
    target: number;
    value: D;
}

export declare interface GraphResponse<D = number> {
    nodes: GraphResponseNode[],
    links: GraphResponseLink<D>[],
}

export declare type PageResponse<D> = {
    count: number;
    next: string;
    previous: string;
    results: D[];
}

export declare interface TreeResponse<D = number> {
    label: string,
    children: TreeResponse<D>[],
    value: D,
}

export declare interface TreeValuesResponse<D> {
    label: string,
    children: TreeValuesResponse<D>[],
    values: D,
}

export declare interface ShallowHierarchyApiResponse<D = number> {
    label: string;
    children: _ShallowHierarchyApiResponse<D>[];
    value: D;
}

declare interface _ShallowHierarchyApiResponse<D = number> {
    label: string;
    children: never[];
    value: D;
}

export declare interface HierarchyApiResponse<D = number> {
    label: string;
    children: HierarchyApiResponse<D>[];
    value: D;
}

export declare interface HierarchyMultiValueResponse<D = number> {
    label: string;
    children: HierarchyMultiValueResponse<D>[];
    values: D;
}

export type BaseHierarchy<D> = HierarchyApiResponse<D> | _ShallowHierarchyApiResponse<D>;

export type MatCommonKpiSerializer = {
    n_categories: number
    n_suppliers: number
    total_spend: number
    unclassified_spend: number
}
export type MatKpiTreeData = {
    spend: number;
    parts: number;
    distinct_suppliers: number; // not existing yet
};
export declare type MatKpiTreeValues = keyof MatKpiTreeData;

export declare type JobPriceVarianceResp = {
    groups: {
        p__description: string;
        group_size: number;
        p__unit_cost__min: number;
        p__unit_cost__sum: number;
        p__quantity__sum: number;
        p__spend__sum: number;
        p__unit_cost__avg: number;
        pv_delta: number;
        pv: number;
        pv_opportunity: number;
    }[];
    total_opportunity: number;
};

/**
 * job_processing.views.JobConfigViewSet.list
 */
export interface ServerConfig {
    SKIP_AI_INTEGRATION: boolean;
    default_type: string;
    all_types: string[];
}

/**
 * KPI's, as taken from /api/bag/kpi_common/{:bagId}/{:CommonKpiBuilder_kpis}/
 * Generated by: http://localhost:8500/api/bag/kpi_common/1/all_names/?format=ts-type
 */
export declare type CommonKpiBuilder_kpis =
    'all'
    | 'get_all_labels'
    | 'get_business_units'
    | 'get_categories'
    | 'get_l1_categories'
    | 'get_suppliers'
    | 'get_total_categories'
    | 'get_total_l1_categories'
    | 'n_business_units'
    | 'n_categories'
    | 'n_l1_categories'
    | 'n_labels'
    | 'n_levels'
    | 'n_parts'
    | 'n_suppliers'
    | 'n_total_categories'
    | 'n_total_l1_categories'
    | 'n_unclassified'
    | 'total_pos_spend'
    | 'total_spend'
    | 'unclassified_pos_spend'
    | 'unclassified_spend';

/**
 * KPI's, as taken from /api/bag/kpi_viz/{:bagId}/{:CommonKpiBuilder_kpis}/
 * Generated by: http://localhost:8500/api/bag/kpi_viz/1/all_names/?format=ts-type
 */
export declare type UserVisualizationKpiBuilder_kpis =
    'categorization_spend_result'
    | 'count_tree'
    | 'count_tree_total'
    | 'get_all_categories'
    | 'get_l1'
    | 'out_spend_tree'
    | 'out_spend_tree_total'
    | 'spend_tree'
    | 'spend_tree_total'
    | 'total_used_labels';
export declare type KpiBuilder_kpis = UserVisualizationKpiBuilder_kpis;

export interface LoginUser {
    id: number
    username: string
    email: string
    first_name: string
    last_name: string
    groups: string[]
    is_staff: boolean
    mithrauserdata: null | MithraUserData
}

export interface MithraUserData {
    is_edit_allowed: boolean
    is_upload_allowed: boolean
}

export interface MithraLoginResponse {
    access_token: string;
    refresh_token: string;
    user: LoginUser;
}

export type ppv_all_groups_Response = {
    ppv: {
        p__in_l1: "" | string,
        pv_opportunity__sum: number,
    }[]
}
export type job_data_Response = {
    p__id: string,
    p__description: string,
    s__id: string,
    bu__id: "" | string,
    p__spend: number,
    p__unit_cost: 0 | number,
    p__quantity: number,
    p__in_l1: "" | string,
    // And more
}
export type job_supplier_meta_Response = {
    s__id: string,
    p__spend__count: number,
    p__spend__sum: number,
    s__payment_term: number,
}
export type CashFlowOpportunityResponse = {
    summary: {
        n_hit_parts: number,
        n_hit_suppliers: number,
        total_opportunity: number,
        total_avg_extended_days_part: number,
        total_avg_extended_days_supplier: number,
    },
    suppliers: any[]
}

export enum SupplierSegmentationEnum {
    cr = 'Critical',
    st = 'Strategic',
    tr = 'Transactional',
}

export type DataFileUploadSerializer = {
    id: number;
    file_name: string;
    date: string;
}

export type DataFrameSerializer = {
    columns: string[];
    data: any[][];
    column_indexes: number[]
    dataframe_id: number;
    dataframe_size: number;
    types: string[];
    columns_letters: string[];
}
//TODO: it's not really a serializer, but it''s 
export type MithraColumnSerializer = {
    example: string;
    id: number;
    name: string;
    type: string;
    key: string;
    description: string;

    is_required: boolean;
}

export type DataMappingSerializer = {
    id?: number;
    data_file: number;
    client_column_name: string;
    client_column_type: string;
    client_column_index: number;
    mithra_column_key: string;
    ai_result: boolean;
    user_result: boolean;
}

export type KpiListSerializer = {
    total_spend: number;
    suppliers: number;
    parts: number;
    business_units: number;
}

export type DataMappingResultTableSerializer = {
    columns: string[];
    data: string[][];
    column_indexes: number[];
    dataframe_size: number;
    types: string[];
}

// 'request_create_review' = ai run finished, 'live' = the job is closed and can't be changed
export enum AiCategorizationJobStatusEnum {
    ERROR = 'error',
    INIT = 'init',
    VERTEX_AI = 'vertex_ai',
    REQUEST_CREATE_REVIEW = 'request_create_review',
    CREATING_REVIEW = 'creating_review',
    REVIEW = 'review',
    REQUEST_CREATE_LIVE = 'request_create_live',
    CREATING_LIVE = 'creating_live',
    LIVE = 'live',
}

export type AiCategorizationJobSerializer = {
    id: number
    src_data_bag: number
    created: string
    ai_task: string

    request_user: User

    src_mat_taxonomy: number
    current_status: {
        timestamp: string
        status: AiCategorizationJobStatusEnum
        status_name: string
    }
}

export type UserCategorizationJobRequestEnum =
    'categorize_all'

export type AiCategorizationJobCreateSerializer = {
    src_data_bag: number
    requested_task: UserCategorizationJobRequestEnum
}

export type TaxonomySuggestionSerializer<T> = {
    id: number;
    create_date: string;
    last_update: string;
    suggestion_state: T;
}

export type TaxonomyHealthCheckSerializer<T> = {
    id: number;
    create_date: string;
    last_update: string;
    suggestion_state: T;
    check_type: string; //FIXME: @Sina, the check_type must be saved into the suggestion_state.node.values
}

export type CategorizationVersionedStatisticsSerializer = {
    id: number,
    version: number,
    actor: number,
    created: string,
    total_parts: number
    average_confidence_score: number,
    very_low_confidence_parts: number,
    low_confidence_parts: number,
    medium_confidence_parts: number,
    high_confidence_parts: number,
    very_high_confidence_parts: number,
    reviewed_parts: number,
    reviewed_spend: number,
    unreviewed_parts: number,
    unreviewed_spend: number,
    categorized_reviewed_spend: number,
    categorized_unreviewed_spend: number,
    ai_categorization_job: number
    reviewed_total_pend: number
    unreviewed_total_spend: number
    covered_by_ai_spend: number
    covered_by_ai_total_spend: number
    covered_by_ai_reviewed_spend: number
    reviewed_total_spend: number
    covered_by_ai_unreviewed_spend: number
    covered_reviewed_suppliers: number
    covered_unreviewed_suppliers: number
    covered_suppliers: number
    total_suppliers: number
    covered_by_ai_reviewed_total_spend: number
    covered_by_ai_unreviewed_total_spend: number
    reviewed_transactions: number
    unreviewed_transactions: number
    total_transactions: number
    total_categories: number
    covered_categories: number
    uncovered_categories: number

    recategorized_spend: number
    recategorized_parts: number
    recategorized_total_spend: number
    recategorized_total_transactions: number
    recategorized_reviewed_spend: number
    recategorized_unreviewed_spend: number
    recategorized_reviewed_parts: number
    recategorized_unreviewed_parts: number
    categorized_spend: number
    categorized_parts: number
    categorized_total_spend: number
    categorized_total_transactions: number
    // categorized_reviewed_spend: number
    // categorized_unreviewed_spend: number
    categorized_reviewed_parts: number
    categorized_unreviewed_parts: number
    uncategorized_spend: number
    uncategorized_parts: number
    uncategorized_total_spend: number
    uncategorized_total_transactions: number
    uncategorized_reviewed_spend: number
    uncategorized_unreviewed_spend: number
    uncategorized_reviewed_parts: number
    uncategorized_unreviewed_parts: number
}

export type AdvancedTreeData = {
    value: number;
    label: string;
    children: AdvancedTreeData[];
};

type Coordinate = number;

export type AdvancedGeoData = {
    country: Country,
    spend: number,
    latitude: Coordinate,
    longitude: Coordinate
};

export type CategoryFilter = [string, string?, string?, string?];

export type AdvancedFilter = {
    country?: string[];
    s_ids?: string[]; // this is an array of strings of s_id's
    sp_ids?: string[];
    category?: CategoryFilter;
    date_range?: [string, string];
};

export type AdvancedSpendPerGroup = {
    date: Date | string,
    spend: number,
    group: string,
    s_id?: string,
};

export type AdvancedSpendData = {
    spend: number
    group: string
    s_id: string
}

export type DonutChartSupplierSpendData = {
    spend: number
    group: string
    s_id: string
} | {
    spend: number
    group: 'Other'
    s_id: null
};

export type AdvancedSpendKPI = {
    n_categories: number
    n_suppliers: number
    total_spend: number
    total_transactions: number
};

export type AdvancedFilterData = {
    id?: string,
    name: string,
    spend: number,
};

export type AdvancedContinentData = {
    id?: string,
    name: string,
    countries: string[],
    spend: number,
};

export type AdvancedOpportunityData = {
    spend: number
    active_l1: string
    active_l2: string
    active_l3: string
    active_l4: string
    opportunity_rank: number
}

export type AdvancedSupplierBreakdownData = {
    s_id: string
    sp_id: string
    parent_s_name: string
    s_name: string
    s_country_code: string
    spend: number
    active_l1: string
    active_l2: string
    active_l3: string
    active_l4: string
    databag_name: string
}

export type SupplierSearchResponse = {
    count: number,
    next: string | null,
    previous: string | null,
    results: SupplierSearchResults[]
}

export type SupplierSearchResults = {
    id: number,
    databag_id: number,
    src_databag_id: number | null,
    s_id: string,
    s_name: string,
    s_city: string,
    s_country: string,
    s_country_code: string,
    s_region: string,
    s_address: string,
    s_postal: string,
    s_duns: string,
    s_context_1: string,
    s_context_2: string,
    s_context_3: string,
    s_extra_1: string,
    s_extra_2: string,
    s_extra_3: string,
}

export type ParentSupplierSearchResponse = {
    count: number,
    next: string | null,
    previous: string | null,
    results: ParentSupplierSearchResults[]
}

export type ParentSupplierSearchResults = {
    id: number
    databag_id: number
    src_databag_id: null | number
    sp_id: string
    sp_name: string
    sp_city: string
    sp_country: string
    sp_region: string
    sp_context_1: string
    sp_context_2: string
    sp_context_3: string
}

export type AggregatedStatusResults = {
    sum_spend: number,
    n_parts: number,
    exists_invalid_suggestion: boolean,
    exists_open_suggestion: boolean,
    all_invalid_suggestion: boolean,
    all_is_reviewed: boolean,
    all_category_is_uncat: boolean,
    category: string[],
    state: string,
    review_user_id_min: number,
    review_user_id_max: number
}
