import "./DataMapping.scss"
import React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {SingularCategorizationReviewTable} from "./MappingResultTable";

export const DatasetCheck: React.FC = observer(() => {

    const {dataIngestionStore} = useStores();


    return <Grid className="data-check-wrapper" justifyContent="center">
        <Grid container
              className="dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DataMappingResultSpendRow
                title="Spend"
                className="categories-row"
                amount={dataIngestionStore.dataMappingResult?.total_spend}>
            </DataMappingResultSpendRow>
            <DataMappingResultRow
                title="Suppliers"
                className="spend-row"
                subtitle={dataIngestionStore.dataMappingResult?.suppliers}>
            </DataMappingResultRow>
            <DataMappingResultRow
                title="Parts"
                className="suppliers-row"
                subtitle={dataIngestionStore.dataMappingResult?.parts}>
            </DataMappingResultRow>
            <DataMappingResultRow
                title="Business Units"
                className="business-units-row"
                subtitle={dataIngestionStore.dataMappingResult?.business_units}>
            </DataMappingResultRow>
        </Grid>

        <Grid container justifyContent="space-evenly" className="data-check-table">
            <Grid item xs={12}>
                <SingularCategorizationReviewTable/>
            </Grid>
        </Grid>
    </Grid>
})


type RowProps = {
    className: string
    title: string
    subtitle: React.ReactNode
}

type spendProps = {
    className: string
    title: string
    amount?: number
}
const DataMappingResultRow: React.FC<RowProps> = ({className, title, subtitle, children}) =>
    <Grid item lg={3} xs={4} className={`dashboard-row ${className}`}>
        <Grid container direction="column" alignItems="center">
            <Grid item className="dashboard-row-title">
                <Typography variant="h2" component="h2">
                    {title}
                </Typography>
            </Grid>
            <Grid item className="dashboard-row-subtitle">
                <Typography variant="subtitle2" component="h2">
                    {subtitle}
                </Typography>
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid item sm={11} xs={12} className="dashboard-row-content">
                {children}
            </Grid>
        </Grid>
    </Grid>

const DataMappingResultSpendRow: React.FC<spendProps> = ({className, title, amount, children}) =>
    <Grid item lg={3} xs={4} className={`dashboard-row ${className}`}>
        <Grid container direction="column" alignItems="center">
            <Grid item className="dashboard-row-title">
                <Typography variant="h2" component="h2">
                    {title}
                </Typography>
            </Grid>
            <Grid item className="dashboard-row-subtitle">
                <Typography variant="subtitle2" component="h2">
                    {amount !== undefined ? <CurrencyComponent v={amount}></CurrencyComponent> : null}
                </Typography>
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid item sm={11} xs={12} className="dashboard-row-content">
                {children}
            </Grid>
        </Grid>
    </Grid>
